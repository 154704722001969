var Pmp = Pmp || {};
Pmp.Direct = Pmp.Direct || {};

Pmp.Direct.ReservationRequest = {
    initialize : function(locale, venueDaysOfOperation, venueCustomOpenedDates,
                          venueBlackoutDates, systemClass, venueId) {
        this._locale = locale;
        this._venueDaysOfOperation = venueDaysOfOperation;
        this._venueCustomOpenedDates = venueCustomOpenedDates;
        this._venueBlackoutDates = venueBlackoutDates;
        this._systemClass = systemClass;
        this._venueId = venueId;

        this._maxMales = 200;
        this._maxFemales = 200;
        var self = this;

        $('#date-calendar').datepicker({
            startDate: dateFns.startOfToday(),
            beforeShowDay: (function(date) { return self._beforeShowDay(date); })
        })
        .on('changeDate', function (e) {
            self._changeDate(e);
            $('#date-calendar').datepicker('hide');

        });
    },

    _changeDate : function(e) {
        var date = dateFns.format(e.date, 'MM-DD-YYYY')
        $.ajax({
            url: '/venue/' + this._venueId + '/time_options/' + date,
            method: 'get',
            success: function(resp) {
                var timeOptions = resp.payload.time_options;
                var arrivalTime = $('#input-arrival-time');
                arrivalTime.find('option').remove();
                for (var o=0 ; o<timeOptions.length; o++) {
                    var option = $("<option />").val(timeOptions[o]).sext(timeOptions[o]);
                    arrivalTime.append(option);
                }
            }
        });
    },

    _beforeShowDay : function(date) {
        var date_formatted = dateFns.format(date, 'MM/DD/YYYY')
        var day_of_week_short = dateFns.format(date, 'ddd')
        var dow_index = $.inArray(day_of_week_short, ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']);
        var td_class = '';

        if ($.inArray(date_formatted, this._venueBlackoutDates) >= 0) {
            td_class = 'ui-datepicker-unselectable ui-state-disabled';
        } else if ((!this._venueDaysOfOperation[dow_index]) && ($.inArray(date_formatted, this._venueCustomOpenedDates) < 0)) {
            td_class = 'ui-datepicker-unselectable ui-state-disabled';
        }
        return {enabled: !td_class, classes: td_class}
    },

    setMaxMales : function(numMales) {
        this._maxMales = numMales;
    },

    setMaxFemales : function(numFemales) {
        this._maxFemales = numFemales;
    },

    showErrorMsg : function(err_field, err_msg) {
        $(err_field).sext(err_msg);
    },

    clearErrorMessages : function (err_field_list) {
        for (var i=0;i<err_field_list.length;i++){
            $(err_field_list[i]).html('&nbsp;');
        }
    },

    validateNotEmpty: function(val, err_field, err_msg) {
        var valid = $.trim(val).length != 0;
        if (!valid) {
            this.showErrorMsg(err_field, err_msg);
        }
        return valid;
    },

    validatePhone: function(val, err_field, err_msg) {
        var valid = this.validateNotEmpty(val);
        if (!valid) {
            this.showErrorMsg(err_field, err_msg);
        }
        return valid;
    },

    validateEmail: function(val, err_field, err_msg) {
        var email_re_str =
        	"(^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*" + // dot-atom
        	'|^"([\\001-\\010\\013\\014\\016-\\037!#-\\[\\]-\\177]|\\\\[\\001-011\\013\\014\\016-\\177])*"' + // quoted-string
        	')@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\\.)+[A-Z]{2,6}\\.?$'; // domain
        // Ignore-case
        var email_re = new RegExp(email_re_str, "i");
        var valid = email_re.test($.trim(val));
        if (!valid) {
            this.showErrorMsg(err_field, err_msg);
        }
        return valid;
    },

    validatePartySize: function(val, max_num, err_field, err_msg) {
        var valid = false;
        var intRegex = /^\d+$/;
        if(intRegex.test(val)) {
            var num = parseInt(val);
            if ((num >= 0) && (num <= max_num)) {
                valid = true;
            }
        }
        if (!valid) {
            this.showErrorMsg(err_field, err_msg);
        }
        return valid;
    },

    validateDate : function(input, err_field, err_msg) {
        var valid = false;
        if (input.datepicker('getDate') === null) {
            input.datepicker('setDate', null);
            valid = false;
        } else {
            valid = true;
        }
        if (!valid) {
            this.showErrorMsg(err_field, err_msg);
        }
        return valid;
    },

    validateAndSubmit: function () {
        var first_name = $('#input-first-name').val();
        var last_name = $('#input-last-name').val();
        var phone_number = $('#input-phone-number').val();
        var email = $('#input-email').val();

        var has_party_size = $('#input-party-size').is(":visible");
        var party_size = $('#input-party-size').val();
        var mf_ratio_male = $('#input-mf-ratio-male').val();
        var mf_ratio_female = $('#input-mf-ratio-female').val();

        this.clearErrorMessages(['#date-error', '#first-name-error', '#last-name-error',
                                 '#phone-error', '#email-error', '#party-size-error']);

        var valid = true;
        valid = this.validateDate($('#date-calendar'), '#date-error' ,'Please enter a valid date') && valid;
        valid = this.validateNotEmpty(first_name, '#first-name-error' ,'Please enter a first name') && valid;
        valid = this.validateNotEmpty(last_name, '#last-name-error' ,'Please enter a last name') && valid;
        valid = this.validatePhone(phone_number, '#phone-error' ,'Please enter a phone number') && valid;
        valid = this.validateEmail(email, '#email-error' ,'Please enter a valid email') && valid;

        var request_type = (this._systemClass === 'guestlist') ? 'Guestlist ' : '';
        console.log(has_party_size)
        if (has_party_size) {
            valid = this.validatePartySize(party_size, this._maxMales, '#party-size-error' ,'Please enter a party size greater than zero') && valid;
        } else {
            if (mf_ratio_male === '' && mf_ratio_female === '') {
                valid = this.validatePartySize(mf_ratio_male, this._maxMales, '#party-size-error' ,'Please enter males and females') && valid;
            }
            if (mf_ratio_male !== '') {
                var plural = this._maxMales > 1 ? 's' : '';
                valid = this.validatePartySize(mf_ratio_male, this._maxMales, '#party-size-error' , request_type + 'requests are limited to ' + this._maxMales + ' male' + plural) && valid;
            }
            if (mf_ratio_female !== '') {
                var plural = this._maxFemales > 1 ? 's' : '';
                valid = this.validatePartySize(mf_ratio_female, this._maxFemales, '#party-size-error' , request_type + 'requests are limited to ' + this._maxFemales + ' female' + plural) && valid;
            }
        }

        if (valid) {
            var button = $('.make-reservation-button');
            if (!button.hasClass('disabled')) {
                button.addClass('disabled');
                button.val('Submitting...');
                $('#custom-request-form').submit();
            }
        }
    }
};

