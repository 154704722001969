/* jQuery Extensions */

// repeats a string n times
// e.g. jQuery.repeat("hello", 2) => "hellohello"
(function($){
    $.extend({
        repeat: function(str, i)
        {
            if (isNaN(i) || i == 0) return "";
            return str + $.repeat(str, i-1);
        },
        nl2br: function(str)
        {
            var breakTag = '<br />';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
        },
        getParam: function(sParam)
        {
          if (!sParam) { return false; }

          var sPageURL = window.location.search.substring(1);
          var sURLVariables = sPageURL.split('&');
          for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
              return sParameterName[1];
            }
          }
          return false;
        },
        deserialize: function deserialize(str, options)
        {
            var pairs = str.split(/&amp;|&/i),
                h = {},
                options = options || {};
            for(var i = 0; i < pairs.length; i++) {
                var kv = pairs[i].split('=');
                kv[0] = decodeURIComponent(kv[0]);
                if(!options.except || options.except.indexOf(kv[0]) == -1) {
                    if((/^\w+\[\w+\]$/).test(kv[0])) {
                        var matches = kv[0].match(/^(\w+)\[(\w+)\]$/);
                        if(typeof h[matches[1]] === 'undefined') {
                            h[matches[1]] = {};
                        }
                        h[matches[1]][matches[2]] = decodeURIComponent(kv[1]);
                    } else {
                        h[kv[0]] = decodeURIComponent(kv[1]);
                    }
                }
            }
            return h;
        },
        isUrlValid: function isUrlValid(url)
        {
            return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(url);
        }
    })
})(jQuery);


$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

$.fn.sext = function sext(value, noescape)
{
    // S[afe]TEXT
    // Null-safe version of $.text since jQuery has their noses in the air on this
    // issue: https://bugs.jquery.com/ticket/13666
    // "since it is invalid input, this behavior is our prerogative"
    if (noescape) {
      return arguments.length ? this.html(value === null ? '' : value) : this.html();
    }

    return arguments.length ? this.text(value === null ? '' : value) : this.text();
};

$.fn.outerHtml = function outerHtml()
{
    return this.wrapAll('<div>').parent().html();
};


//add cross browser trim support

if(typeof String.prototype.trim !== 'function') {
  String.prototype.trim = function() {
    return this.replace(/^\s+|\s+$/g, '');
  }
}

var Uploader = {

  _default: function(response)
  {
    // do stuff
  },

  _photohandler: function(response)
  {
    // render thumbnail along with other stuff
  },


  photo: function(selector, success, onProgress, rurl)
  {
      if( !success ) {
          success = this._photohandler;
      }
      this.fire(selector, success, function(data) { return data.photo_key; }, onProgress, false, rurl);
  },

  uploadPhotoAndReturnData: function(selector, success, onProgress, rurl)
  {
      if( !success ) {
          success = this._photohandler;
      }
      this.fire(selector, success, function(data) { return data; }, onProgress, false, rurl);
  },
  advanced: function(selector, success, onProgress, onAdd, rurl, overrideAdd)
  {
    if(!success) {
      success = this._default;
    }
    this.fire(selector, success, function(data) { return data; }, onProgress, onAdd, rurl, overrideAdd);
  },
  basic: function(selector, success, onProgress, onAdd, rurl)
  {
    if( !success ) {
      success = this._default;
    }
    this.fire(selector, success, function(data) { return data; }, onProgress, onAdd, rurl);
  },

  fire: function(selector, success, extract_param, onProgress, onAdd, rurl, overrideAdd)
  {
    var that = this;
    $(selector).fileupload({
      dataType: 'iframe',
      forceIframeTransport: true,
      autoUpload: false,
      add: function (e, data) {
        if (onAdd) {
          onAdd();
        }
        if (overrideAdd) {
          return overrideAdd(data);
        }

        var success = false;
        var redirect_url = rurl || $(selector).attr('rurl');

        $.ajax({
          url: '/upload-url?rurl=' + redirect_url,
          method: 'post',
          async: false,
          success: function(response_data) {
            var upload_url = response_data.upload_url;
            $(selector).fileupload('option','url',upload_url);
            success = true;
          }
        });
        if (success) {
          data.submit();
        }
      },
      progress : function(e, data) {
        if (onProgress) {
          onProgress(data.loaded,data.total);
        }
      },
      done: function(e, data) {
        var jsonstr = data.result[0].body.innerText
        var result = jQuery.parseJSON(jsonstr);
        success(e, extract_param(result), result);
      }
    });
  },

  convertImgToBase64URL : function(url, outputFormat, rurl, callback) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d', url);
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat);
        var parsedDataURL = dataURL.split(",");
        callback(parsedDataURL[1], outputFormat, rurl);
        canvas = null;
    };
    img.src = url;
  },

  convertBase64URLToBlob : function(dataURL, contentType, rurl, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(dataURL);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {type: contentType});

    // Begin Photo Upload - first get upload URL
    $.ajax({
        url: '/upload-url?rurl=' + rurl,
        method: 'post',
        async: false,
      }).then(function(response_data) {
        // Create FormData element to send
        var photoElement = new FormData();
        photoElement.append('photo', blob);
        var csrftokenValue = $("input[name=csrftoken]").attr("value");
        photoElement.append('csrftoken', csrftokenValue)

        var upload_url = response_data['upload_url']
        $.ajax({
          url: upload_url,
          type: 'POST',
          processData: false,
          contentType: false,
          data: photoElement,
        }).then(function(data) {
          // Data is returned as a string
          photo_key = data.split('"')[3];
          Pmp.Common.PhotoCropper.onImageUpload(data.split('"')[3]);
        });
      });
  },

  beginFacebookUpload : function(photo_url, imageType, rurl) {
    /*
     * Converting an image from an external URL via JS requires conversion of the image to base64.
     * From there, the base64 URL is converted to a blob which is appended to a FormData element.
     * Then, the FormData element retrieves the upload URL and uploads the image, saving and
     * return a photo key for cropping. (Basically simulating fileupload plugin)
     */
    this.convertImgToBase64URL(photo_url, imageType, rurl, this.convertBase64URLToBlob);
  },
};

// Support indexOf in IE7/IE8
if(!Array.indexOf){
	Array.prototype.indexOf = function(obj){
		for(var i=0; i<this.length; i++){
			if(this[i]==obj){
				return i;
			}
		}
		return -1;
	}
}



var formutils = {

  init: function()
  {
    this.checks = $('.form-element.checkbox input');
    this.radios = $('.form-element.radio input');
    this.quicks = $('.form-element.quicksave');

    this.all = $('input, textarea, select');

    $('.radioset').on('click', this.canuse);

    this.checkload();
    this.savevalue();

    this.all.on('click focus mousedown', this.canuse);

    $('body').on('click', '.form-element.radio input', this.radio);

    this.quicks.find('input, textarea').on('keyup', this.addsave);

    this.quicks.find('.cancel').on('click', function() {
      var $el = $(this).closest('.form-element');
      input = $el.find('input, textarea')[0];
      $(input).val($.data(input, 'initial'));
      $el.find('.controls').hide();
    });

    this.quicks.find('.save').on('click', function(e) {
      var $el = $(this).closest('.form-element');
      var form = $(e.target).closest('form');
      var validatorType = $el.attr('has-validator');
      // create dynamic validator if present
      var valid = true;
      if (validatorType) {
        var validator = new sr.Validator(form.parent());
        valid = validator.validate();
      }

      if (!valid) {
        var $this = $(this);
        $this.closest('.form-element').find('.controls').hide();
        return;
      }
      metric.track('Settings.' + form[0].id, {'page': 'settings.general'});
      $.ajax({
        url: $(form).prop('action'),
        method: $(form).prop('method'),
        data: $(form).serialize(),
        success: function(response) {
          input = $el.find('input, textarea')[0];
          $.data(input, 'initial', $(input).val());
          $el.find('.controls').hide();
        }
      });
    });

    $('body').on('click', '.form-element.checkbox input', this.checker);
  },

  savevalue: function()
  {
    this.quicks.find('input, textarea').each(function() {
      var $this = $(this);
      $.data(this, 'initial', $this.val());
    });
  },

  addsave: function()
  {
    var $this = $(this);
    $this.closest('.form-element').find('.controls').show();
  },

  checkload: function()
  {
    this.checks.filter(':checked').closest('.form-element').addClass('checked');
    this.radios.filter(':checked').closest('.form-element').addClass('checked');
  },

  canuse: function(e)
  {
    if( $(this).hasClass('disabled') ) {
      $(this).blur();
      return false;
    }
  },

  ae: function(input)
  {

    // ignore inputs that are not in an artificial exclusion container
    var set = input.closest('.artificial-exclusion');
    if( !set.length ) return false;

    // ignore inputs that have a no exclusion container
    var noexclusion = input.closest('.no-exclusion');
    if (noexclusion.length) return false;

    // set checked property
    set.find('.radio input, .checkbox input').not('[data-reactid]').prop('checked', false);
    set.find('.form-element').not('#id_undefined').not('[data-reactid]').removeClass('checked');

    // show/hide suboption
    var so = input.parents('.form-element').next('.sub-option');
    var allso = input.parents('.artificial-exclusion').children('.sub-option');
    allso.hide();
    so.show();

    return true;
  },

  radio: function(e)
  {
    var chosen = $(e.target);
    var others = $(e.target).closest('.radioset').find('.form-element').removeClass('checked');

    formutils.ae(chosen);

    chosen.closest('.form-element').addClass('checked');

    $('[name=' + chosen.prop('name') + ']').prop('checked', false);
    chosen.prop('checked', true);
  },

  checker: function(e)
  {
    var chosen = $(e.target);
    var element = $(e.target).closest('.form-element');
    var input = element.find('input');

    var hack = formutils.ae(chosen);

    element.toggleClass('checked');
    if( hack ) {
      input.prop('checked', true);
    }


    return true;
  }
};

var decorations = {

  init: function()
  {
    this.faders();
  },

  faders: function()
  {
    var $fades = $('.fade');

    $fades.delay(8000).fadeOut(2000);
  }
};

var interactions = {

  init: function()
  {
    this.expanders();
  },

  expanders: function()
  {
    $('body').on('click', '.expander', function(e) {
      $(e.target).closest('.expand-target').toggleClass('expanded');
    });
  }

};

var tooltip = {

  init: function()
  {

    this.$tip = $('<div/>').prop('id', 'tool-tip-box');
    this.$arrow = $('<span/>').prop('id', 'tool-tip-arrow');
    this.$cont = $('<span/>');

    this.$tip.css({
      'position': 'absolute'
    });

    this.$tip.append(this.$arrow);
    this.$tip.append(this.$cont);
    $('body').append(this.$tip);
    this.$tip.hide();

    var that = this;
    $('body').on('click', '.tool-tip', function(e) { that.showtip(this, e); });
    this.$tip.on('mouseleave', this.hidetip);
  },
  showtip: function(obj, e)
  {
    var $obj = $(obj),
        coords = $obj.offset(),
        text = $obj.children('span').sext();

    this.$tip.css({
      'left': coords.left - 7 - this.$tip.width() / 2,
      'top': coords.top + 6,
      'margin-top': $obj.height()
    });

    this.$cont.sext(text);
    this.$tip.show();
  },
  hidetip: function(e)
  {
    $(this).hide();
  }
};

var timeutils = {
    sort_order_to_time: function(sort_order, is_military_time, start_of_day_hour_override)
    {
      var start_of_day_hour = 6;
      if (start_of_day_hour_override) {
        start_of_day_hour = start_of_day_hour_override;
      } else if (typeof Pmp.Manager.Global.start_of_day_hour === 'number') {
        start_of_day_hour = Pmp.Manager.Global.start_of_day_hour;
      }
      var minutes_since_start_of_day = sort_order * 15;
      var minutes = minutes_since_start_of_day % 60;
      if (minutes == 0) {
        minutes = '00';
      }
      var hours_since_start_of_day = Math.floor(minutes_since_start_of_day / 60);
      var hour = hours_since_start_of_day + start_of_day_hour;

      if (is_military_time) {
        var h = hour % 24;
        if (h < 10) {
          h = '0' + h;
        }
        return h + ':' + minutes;
      }

      var period;
      if (hour < 12) {
        period = 'AM';
      } else if (hour < 24 ) {
        period = 'PM';
      } else {
        period = 'AM';
      }
      hour = hour % 12;
      if (hour == 0) {
        hour = 12;
      }
      return hour + ':' + minutes + ' ' + period;
    }
};

$(function() {
  decorations.init();
  formutils.init();
  tooltip.init();
  interactions.init();
});


/* Utility Functions */

var Pmp = Pmp || {};
Pmp.Utils = Pmp.Utils || {};


Pmp.Utils.formatDatePicker = function (dateSel, shortMonth) {
  // Nice formatting.
  // NOTE: is alternative to using datepicker('option', 'dateFormat')
  // b/c it only accepts current year if the format doesn't have a year in the format
  if (!dateSel || !dateSel.length) {
    return;
  }
  var dateObj = $(dateSel).datepicker('getDate');
  var dateFmt = Pmp.Utils.dateFormatMedium(Pmp.Manager.Global._locale);
  if (dateObj && _.isDate(dateObj)) {
    if (shortMonth) {
      dateFmt = Pmp.Utils.dateFormatMediumShortMonth(Pmp.Manager.Global._locale);
    }
    $(dateSel).val(
      $.datepicker.formatDate(
        dateFmt,
        dateObj
      )
    );
  }
};

Pmp.Utils.militaryTimeToLocale = function(timeString) {
    return (Pmp.Manager.Global._is_military_time) ? timeString : Pmp.Utils.militaryTimeToAMPM(timeString);
};

Pmp.Utils.parseDate = function (dateString, locale) {
  var dateFmt = Pmp.Utils.dateFormat(locale);
  return $.datepicker.parseDate(dateFmt, dateString);
};

Pmp.Utils.parseDateUrlParam = function (dateString) {
  var dateFmt = 'mm-dd-yy';
  return $.datepicker.parseDate(dateFmt, dateString);
};


Pmp.Utils.toAMPM = function (timeString) {
  return (Pmp.Manager.Global._is_military_time) ? Pmp.Utils.militaryTimeToAMPM(timeString) : timeString;
};

/**
 * Helper util to get string converted from '10:00:00' or '10:00' to '10:00 AM'
 * @param timeString
 * @returns {*}
 */
Pmp.Utils.militaryTimeToAMPM=function (timeString) {
  if (!timeString) {
    return timeString;
  }

  var timeComponents = timeString.split(':'),
      numComponents = _.size(timeComponents);
  if (numComponents < 2 || numComponents > 3) {
    throw "Invalid string " + timeString;
  }
  var hours = timeComponents[0] % 12,
      mins = timeComponents[1],
      isPM = timeComponents[0] >= 12;

  return ((hours ? hours : 12)  + ':' +
      (mins) +
      (isPM ? ' PM': ' AM'));
};


Pmp.Utils.timeWithLocale = function (timeString) {
  return (Pmp.Manager.Global._is_military_time) ? Pmp.Utils.toMilitaryTime(timeString): timeString;
};

/**
 * Helper util to get string converted from'10:00 AM' to '10:00:00'
 * @param timeString
 * @returns {*}
 */
Pmp.Utils.toMilitaryTime=function (timeString) {
  if (!timeString) {
    return timeString;
  }
  timeString = $.trim(timeString);
  var timeComponents = timeString.split(' '),
      numComponents = _.size(timeComponents);
  if (numComponents != 2) {
    throw "Invalid string " + timeString;
  }
  var amPm = $.trim(timeComponents[1]).toUpperCase();
  var hoursMins = timeComponents[0].split(':');
  var hours = Number(hoursMins[0]),
      mins = hoursMins[1],
      isPM = amPm === 'PM';
  if (isPM) {
    if (hours < 12) {
      hours += 12;
    }
  } else if (hours>=0 && hours<10) {
    hours = '0' + hours;
  } else if (hours == 12) {
    hours = '00';
  }
  return (hours + ':' + mins);
};

Pmp.Utils.isMonthDayDateFormat = function () {
  var locale = Pmp.Manager.Global._locale;
  return $.inArray(locale, ['en_US', 'en_CA']) != -1;
}

Pmp.Utils.dateFormat = function (locale) {
  var dateFmt = 'mm/dd/yy';
  if ($.inArray(locale, ['en_US', 'en_CA']) == -1) {
    dateFmt = 'dd/mm/yy';
  }
  return dateFmt;
};

Pmp.Utils.dateFormatMediumShortMonth = function (locale) {
  var dateFmt = 'D, d M';
  if ($.inArray(locale, ['en_US', 'en_CA']) != -1) {
    dateFmt = 'D, M d';
  }
  return dateFmt;
}

Pmp.Utils.dateFormatMedium = function (locale) {
  var dateFmt = 'D, d MM';
  if ($.inArray(locale, ['en_US', 'en_CA']) != -1) {
    dateFmt = 'D, MM d';
  }
  return dateFmt;
}

Pmp.Utils.dateFormatWithYear = function (locale) {
  var dateFmt = 'd M, yy';
  if ($.inArray(locale, ['en_US', 'en_CA']) != -1) {
    dateFmt = 'M d, yy';
  }
  return dateFmt;
}


Pmp.Utils.JsDateToPythonWeekday = function(jsDate) {
  var dow_js = jsDate.getDay();
  // python 0 is Monday, in js its Sunday. faaack
  dow_py = dow_js - 1;
  if (dow_py < 0) { dow_py = 6; }

  return dow_py
};

Pmp.Utils.BindColorPicker = function(elem) {

  elem = $(elem);

  var container = elem.find('.colorpicker-container');
  var colorpicker = elem.find('.colorpicker');
  var input_picker = elem.find('.input-colorpicker');
  var input_val = elem.find('.input-colorpicker-val');
  var area = elem.find('.input-area-colorpicker');

  $colorpicker = $.farbtastic(colorpicker);
  $colorpicker.setColor(input_val.val());
  $colorpicker.linkTo(function(color) {
    input_picker.css('background-color', color);
    input_val.val(color);
    Pmp.Utils.RefreshColors($(elem));
  });
  Pmp.Utils.AddClickDropdown(area, container);
};


Pmp.Utils.RefreshColors = function(elem) {
  elem = $(elem);
  var color_rgb = $(elem).find('.input-colorpicker-val').val();
  $(elem).find('.input-colorpicker').css('background-color', color_rgb);
};


Pmp.Utils.LocalizeDatePicker = function(locale, selector, alt_selector, alt_format) {
	if (locale == "en_US") {
		locale = "";
	}
	if (!alt_format) {
		alt_format = "mm/dd/yy";
	}
	var regional = $.datepicker.regional[locale];
	$(selector).datepicker(regional);
	$(selector).datepicker('option', 'altField', alt_selector);
	$(selector).datepicker('option', 'altFormat', alt_format);
	$(selector).attr('autocomplete', 'off');
};


Pmp.Utils.json_decode = function(rep)
{
    return eval(rep);
};

Pmp.Utils.json_encode = function(obj, depth)
{
    if(typeof obj == 'string')
    {
        return obj;
    }
    if(typeof depth == 'undefined')
    {
        depth = 0;
    }

    rep = "\n";
    for(i in obj) {
        rep += jQuery.repeat("   ", depth) + i + " => " + Pmp.Utils.json_encode(obj[i], depth+1) + "\n";
    }
    return rep;
};

Pmp.Utils.AddPopup = function(link, popupParentID, popupID, page, widthpx, createNew)
{
	Pmp.Utils._CreatePopup($(link), popupParentID, popupID, page, widthpx, createNew);
};

Pmp.Utils.CreateDynamicPopup = function(popupParentID, popupID, page, widthpx, onCompleteFnHook, onClosedFnHook)
{
	Pmp.Utils._CreatePopup($, popupParentID, popupID, page, widthpx, null, onCompleteFnHook, onClosedFnHook);
};

Pmp.Utils._CreatePopup = function(target, popupParentID, popupID, page, widthpx, createNew, onCompleteFnHook, onClosedFnHook)
{
	var colorBoxSettings = {
		    transition: "elastic",
		    speed: 100,
		    opacity: .6,
		    width: widthpx,
		    scrolling: false,
		    onComplete: function()
		    {
	        page.Ajaxify(page.getContentID(), popupID);
	    		jQuery.colorbox.resize();
	    		if (onCompleteFnHook) {
	    			onCompleteFnHook();
	    		}
		    },
		    onClosed : function()
		    {
		    	if (onClosedFnHook) {
		    		onClosedFnHook();
		    	}
		    }
		};

	if (createNew) {
		colorBoxSettings.html = $(popupParentID).html();
	} else {
		colorBoxSettings.inline = true;
		colorBoxSettings.href = popupParentID;
	}
	target.colorbox(colorBoxSettings);
};

Pmp.Utils.LoadUrl = function(url, load_target, onload_callback)
{
	Pmp.Client.Static.Page.Load('#nightloop', url, false /*data*/, false /*is_post*/, $(load_target), onload_callback);
};

Pmp.Utils.LoadForm = function(event, formObj, load_target, onload_callback, break_ajax)
{
	var targetObj = $(load_target);
	Pmp.Client.HandleFormSubmit(event, '#nightloop', formObj, targetObj, onload_callback, break_ajax);
};

Pmp.Utils.FlipVisible = function (id)
{
	var visible = $(id).hasClass('no-display');
	if (visible)
		$(id).removeClass('no-display');
	else
		$(id).addClass('no-display');
	return visible;
};

Pmp.Utils.AddHover = function(cls) {
	var hoverCls = cls+"-hover";
	$("." + cls).hover(
		function() {
			$(this).addClass(hoverCls);
		},
		function()  {
			$(this).removeClass(hoverCls);
		}
	);
};

Pmp.Utils.GetParentElemForEventHandle = function(eventElement, parentSelector) {
	// TODO: Add PrototypeJS .bind functionality to avoid click handler propagation and get rid of this function
	var target = $(eventElement.target);
	if (target.is(parentSelector)) {
		return target;
	} else {
		return target.closest(parentSelector);
	}
};

Pmp.Utils.AddDropdown = function(linkID, divID) {
	$(linkID).mouseenter(function() {
		$(divID).show();
		return true;
	});
	$(linkID).mouseleave(function() {
		$(divID).hide();
		return true;
	});
};

Pmp.Utils.AddClickDropdown = function(linkID, divID) {
	$(linkID).click(function() {
		var ddown = $(divID);
		if (ddown.is(':visible')) {
			ddown.hide();
		} else {
			ddown.show();
		}
		return true;
	});
	$(linkID).mouseleave(function() {
		$(divID).hide();
		return true;
	});
};

Pmp.Utils.AddCommasToNumber= function(nStr) {
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	var safety_count = 0;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
		safety_count ++;
		if (safety_count > 100) {
			break;
		}
	}
	return x1 + x2;
};

Pmp.Utils.GetCookieValue = function(cookie_name) {
	var ARRcookies=document.cookie.split(";");
	for (var i=0;i<ARRcookies.length;i++) {
		var name = ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
		var value = ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
		name = name.replace(/^\s+|\s+$/g,"");
		if (name == cookie_name) {
			return unescape(value);
	  	}
	}
};

Pmp.Utils.ResizeColorboxOnLoad = function(popup_div_selector) {
	// This works since url loading is currently synchronous
	// Make sure all img tags are loaded before resizing to avoid scrollbar appearing
	$(popup_div_selector).find('img').batchImageLoad({
		loadingCompleteCallback: $.colorbox.resize
	});
};

Pmp.Utils.OnFocusInputOverlayPromptField = function(element, prompt) {
	$(element).siblings(prompt).hide();
};

Pmp.Utils.OnFocusOutInputOverlayPromptField = function(element, prompt) {
	if ($(element).val().length == 0) {
		$(element).siblings(prompt).show();
	}
};

Pmp.Utils.OnClickInputOverlayPromptField = function(element, is_textarea) {
	$(element).hide();
	var input_type = 'input';
	if (is_textarea) { input_type = 'textarea'; }
	var inputObj = $(element).siblings(input_type);
	if (! inputObj.is(':disabled')) {
	    inputObj.focus();
	}
};

Pmp.Utils.TextareaOverlayPrompt = function(container, prompt) {
    Pmp.Utils.InputOverlayPrompt(container, prompt, true)
};

Pmp.Utils.InputOverlayPrompt = function(container, prompt, is_textarea) {
	var OnFocusInputOverlayPromptFieldFn = function(event) {
		Pmp.Utils.OnFocusInputOverlayPromptField($(this), prompt);
	};
	var OnFocusOutInputOverlayPromptFieldFn = function(event) {
		Pmp.Utils.OnFocusOutInputOverlayPromptField($(this), prompt);
	};
	var OnClickInputOverlayPromptFieldFn = function(event) {
		Pmp.Utils.OnClickInputOverlayPromptField($(this), is_textarea);
	};

	var input_type = ' input';
	if (is_textarea) { input_type = ' textarea'; }

	$(container + input_type).focus(OnFocusInputOverlayPromptFieldFn);
	$(container + input_type).focusout(OnFocusOutInputOverlayPromptFieldFn);
	$(prompt).click(OnClickInputOverlayPromptFieldFn);

	// In case the form field already has content
	$(container + input_type).each(OnFocusInputOverlayPromptFieldFn);
	$(container + input_type).each(OnFocusOutInputOverlayPromptFieldFn);
};

Pmp.Utils.GoldButtonClickSubmit = function(btn_selector, form_selector, custom_handler, callback) {
  var btn = $(btn_selector);
	var form;
	if (form_selector === undefined) {
		form = $(btn).closest('form');
	} else {
		form = $(form_selector);
	}
	$(btn).click(function(event) {
		if (!$(btn).hasClass('disabled')) {
			$(btn).addClass('disabled pending');
			if (custom_handler != undefined) {
				custom_handler($(form), btn, event); // take the form object for submission
			} else {
				$(form).submit();
			}
		};
    if (callback) {
      callback();
    }
	});
};

Pmp.Utils.GoldButtonClickAnimation = function(btn_selector,custom_handler, live_func) {
	var handle_func = function(event) {
		if (!$(this).hasClass('disabled')) {
			$(this).addClass('disabled pending');
			if (custom_handler != undefined) {
				custom_handler(this); // take the button object
			}
		};
	};

	if (live_func) {
		live_func(btn_selector, 'click', handle_func, true);
	}

	$(btn_selector).click(handle_func);
};


Pmp.Utils.GoldButtonReset = function(btn_selector) {
	var btn = $(btn_selector);
	if ($(btn).hasClass('disabled')) {
		$(btn).removeClass('disabled');
	}
	if ($(btn).hasClass('pending')) {
		$(btn).removeClass('pending');
	}
};

Pmp.Utils.CreateChart = function (createFunc) {
  if (window.google) {
    window.google.charts.setOnLoadCallback(createFunc)
  }
}

Pmp.Utils.isTouchEnabled = function() {
	return ('ontouchstart' in window);
};

Pmp.Utils.toSafeHtml = function(s) {
	return s.replace(/</ig, '&lt;').replace(/>/ig, '&gt;');
};


Pmp.Utils.AllowPhonePaste = function(phoneDivId0, phoneDivId1, phoneDivId2, phoneDivId3) {
	var enforcePhoneMaxLengthFn = function() {
		var value = $(phoneDivId1).val();
		if (value.length > 3) {
			$(phoneDivId1).val(value.substring(0,3));
		}
	};

	var pasteFn = function() {
		var pastedData = $(phoneDivId1).val();
		pastedData = pastedData.replace(/\s|\.|\-|\(|\)/g, '');
		if (pastedData.length == 7) {
			$(phoneDivId1).val('');
			$(phoneDivId2).val(pastedData.substring(0,3));
			$(phoneDivId3).val(pastedData.substring(3,7));
		} else if (pastedData.length == 10) {
			$(phoneDivId1).val(pastedData.substring(0,3));
			$(phoneDivId2).val(pastedData.substring(3,6));
			$(phoneDivId3).val(pastedData.substring(6,10));
		} else if (pastedData.length > 3) {
			$(phoneDivId1).val(pastedData.substring(0,3));
		} else {
			$(phoneDivId1).val(pastedData);
		}
	};

	$(phoneDivId1).bind('paste', function() {  setTimeout(pasteFn, 1); });
	$(phoneDivId1).keyup(enforcePhoneMaxLengthFn);
	$(phoneDivId1).change(enforcePhoneMaxLengthFn);
};

Pmp.Utils.AllowMoveCursor = function(divArray, sizeArray) {
	if (divArray.length != sizeArray.length) { return; }

	var moveCursor = function(idx) {
		return function() {
			var divObj = $(divArray[idx]);
			var divObjNext = $(divArray[idx+1]);
			var maxSize = sizeArray[idx];
			if (divObj.val().length == maxSize) {
				divObjNext.focus();
				divObjNext.change();
			}
		}
	};

	var size = divArray.length - 1;
	for (var i=0; i<size; i++) {
		var localMoveCursor = moveCursor(i);
		$(divArray[i]).keyup(localMoveCursor);
		$(divArray[i]).change(localMoveCursor);
	}
};


Pmp.Utils.TextColorFromBackgroundColor = function(val, is_hex) {
	var toHex = function(x) {
		return ("0" + parseInt(x).toString(16)).slice(-2);
	}
	var filteredHex = null;

	if (is_hex || val.substring(0,1) == '#') {
		filteredHex = val.replace('#','');
	} else {
		val = val.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
		filteredHex = toHex(val[1]) + toHex(val[2]) + toHex(val[3]);
	}

	var bigint = parseInt(filteredHex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  var rgbVal = 0;
  var normalized = 1 - ( 0.299 * r + 0.587 * g + 0.114 * b)/255;

  if (normalized < 0.5) {
  	rgbVal = 0;
  } else {
  	rgbVal = 255;
  }

  var componentToHex = function(c) {
      var local_hex = c.toString(16);
      return local_hex.length == 1 ? "0" + local_hex : local_hex;
  };

  var final_hex = "#" + componentToHex(rgbVal) + componentToHex(rgbVal) + componentToHex(rgbVal);
  return final_hex;
};

/**
 * Takes a spend value string (e.g. $2,500) and turns it into
 * a javascript number type. Useful as a precursor to using
 * the formatBigNum function below.
 * @param {string} spend string
 * return {Number}
 */
Pmp.Utils.spendToNum = function(spend) {

  // just get the digits
  var spendCleaned = spend.replace(/\D/g,'');
  return parseInt(spendCleaned);

  // the below doens't take into account other currency codes
  //return parseInt(spend.split(',')
  //                  .join('')
  //                  .slice(1));
};

/**
 * Formats number to look like 1.2M or 2.5K, etc
 * @param {Number} The Number to format, Defaults to zero ('0')
 * @param {Number} decimal precision (e.g 1.234M vs 1.2M)
 * return {String} Formatted number with metric suffix at end
 */
Pmp.Utils.formatBigNum = function(num, precision) {
  num = num || 0;
  precision = precision || 1;
  var divisor = 1;
  var suffix = '';
  if (num >= 1e9) {
    divisor = 1e9;
    suffix = 'B';
  } else if (num >= 1e6) {
    divisor = 1e6;
    suffix = 'M';
  } else if (num >= 1e3) {
    divisor = 1e3;
    suffix = 'K';
  } else {
    // coerce to string here to make the return signature consistent
    return num + '';
  }
  var ratio = (num/divisor);
  // ~~n is shorthand for the floor function of n
  precision = (ratio === ~~ratio)? 0: precision;
  var formatted_num = ratio.toFixed(precision) + suffix;
  return formatted_num;
};

Pmp.Utils.getFullName = function(full_name) {
  return $('#contact-form').find('[name=' + full_name + ']').val().split(" ")
}

Pmp.Utils.parseName = function(full_name) {
  var name = new Object();
  if (full_name.length === 2) {
    name.first_name = full_name[0]
    name.last_name = full_name[1]
  } else if (full_name.length === 1) {
    name.first_name = full_name[0]
    name.last_name = ' '
  } else {
    name.first_name = full_name.slice(0, full_name.length - 1).join(" ")
    name.last_name = full_name[full_name.length - 1]
  }
  return name
}

/**
 * Extract parameters from a raw query string (everything after the ?)
 * @param query
 * @returns {{}}
 */

Pmp.Utils.deparam = function(query) {
    var params   = {}, e,
        re       = /([^&=]+)=?([^&]*)/g,
        decodeRE = /\+/g,
        decode   = function (str) {
            return decodeURIComponent(str.replace(decodeRE, " "));
        };

    while (e = re.exec(query)) {
        var k = decode(e[1]), v = decode(e[2]);
        if (k.substring(k.length - 2) === '[]') {
            k = k.substring(0, k.length - 2);
            (params[k] || (params[k] = [])).push(v);
        }
        else params[k] = v;
    }
    return params;
};

/**
 * Get the parameters from the current url
 * @returns {{}}
 */
Pmp.Utils.getParamsFromUrl = function() {
  var url = window.location.search, re = /\?(\S+)$/g, queryStringResults, queryString;

  queryStringResults = re.exec(url)

  if (queryStringResults && queryStringResults.length === 2){
    queryString = queryStringResults[1];
    return Pmp.Utils.deparam(queryString);
  }

  return {}
}

Pmp.Utils.updateParametersInUrl = function (toUpdate) {
  var currentParameters = Pmp.Utils.getParamsFromUrl();
  var combined = _.extend(currentParameters, toUpdate);

  var stringifiedAndEscapedArgs = _.map(combined, function (value, name) {
    return (name + '=' + window.encodeURIComponent(value));
  })
  var newQueryString = stringifiedAndEscapedArgs.join('&');

  var fakeLink = document.createElement("a");
  fakeLink.href = location.href;
  fakeLink.search = newQueryString
  window.history.replaceState({}, undefined, fakeLink.href)

}

/**
 * A factory that returns interfaces for interacting with product tours.
 * Just make sure a particular tour has an entry in the object below
 * and call this factory method with the correct name.
 * @param {string} name of page, .e.g reservations
 * return {object} An Intro object that can be used to start tours
 */
Pmp.Utils.IntroFactory = function (intro_page) {
  var tour_available = {
    element: '#tour-guide-button',
    intro: '<pre>' +
    'You can see the tour whenever you want.\n' +
    'Click the \"?\"' +
    '</pre>',
    position: 'left'
  };

  var pages = {
    reservations : {
      steps: [
          {
            element: '#content-header h2',
            intro: '<pre>' +
            'Welcome to the new Sevenrooms!\n' +
            '</pre>',
            position: 'bottom'
          },
          {
            element: '#monthly-calendar',
            intro: '<pre>' +
            'Your calendar will always default to the\n' +
            'current day when logged in, and it will\n' +
            'be circled. As you select a date, it will\n' +
            'turn blue. <b>Bold</b> days have existing\n' +
            'reservations. Dates in black circles\n' +
            'have been blacked out for reservations.\n' +
            '</pre>',
            position: 'right'
          },
          {
            element: 'ul.submenu.regroupmenu',
            intro: '<pre>' +
            'Check it out! You can view\n' +
            'reservations grouped by type.\n' +
            '</pre>',
            position: 'right'
          },
          {
            element: '#book-new',
            intro: '<pre>' +
            'Book a new reservation.\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '.actions',
            intro: '<pre>' +
            'Here you can access quick links specific\n' +
            'to the date you are working on.\n' +
            'Blackout the date for reservations, add\n' +
            'relevant notes for this day, and a quick\n' +
            'link to print all reservations\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '.thumb',
            intro: '<pre>' +
            'Smile. You can now add a photo\n' +
            'with your account.\n' +
            '</pre>',
            position: 'left'
          },
          tour_available
        ]
    },

    reservation_slide: {
      steps: [
          {
            element: '#interface-navigation li.reservation',
            intro: '<pre>' +
            'By selecting reservation, you can\n' +
            'quickly see, edit, and add information\n' +
            'pertaining to this reservation.' +
            '</pre>',
            position: 'left'
          },
          {
            element: '#nav-chat',
            intro: '<pre>' +
            'Keep up to date! You can consolidate all\n' +
            'your communication with your clients in\n' +
            'this chat. While chatting here, your\n' +
            'clients will be receiving the messages\n' +
            'via email. You can send attachments\n' +
            '(menu\'s credit card authorization form,\n' +
            'etc.) directly through here as well.\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '#nav-profile',
            intro: '<pre>' +
            'Now access the client profile directly on\n' +
            'the reservation without having to open a\n' +
            'new page\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '#nav-attachments',
            intro: '<pre>' +
            'Need to find a file you sent quickly?\n' +
            'Click on the attachment tab.\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '#nav-activity',
            intro: '<pre>' +
            'View the activitity for this reservation.\n' +
            '</pre>',
            position: 'left'
          },
          {
            element: '.followers',
            intro: '<pre>' +
            'Does somebody need to know about this\n' +
            'client and reservation? Add them as a\n' +
            'follower. They\'ll receive e-mail\n' +
            'notifications.' +
            '</pre>',
            position: 'left'
          }
      ]
    },
    requests: {
      steps: [
          {
            element: '.current',
            intro: '<pre>' +
            'You can view your reservation requests\n' +
            'all together, or by date. Click the\n' +
            'dropdown to view by date.\n' +
            '</pre>',
            position: 'right'
          },
          {
            element: '#request-filters',
            intro: '<pre>' +
            'Or, your can sort requests a number of\n' +
            'different ways by using these pre-built\n' +
            'filters: sort by expiration time,\n' +
            'response needed from either party, or\n' +
            'through the source by which the\n' +
            'request was sent.\n' +
            '</pre>',
            position: 'right'
          },
          {
            element: '#requests-link span',
            intro: '<pre>' +
            'To learn more about requests, watch\n' +
            'this 3 minute video.\n' +
            '<a target=\"_blank\" href=\"http://screencast.com/t/XAfzNAYKZ\">http://screencast.com/t/XAfzNAYKZ</a>\n' +
            '</pre>',
            position: 'right'
          },
          tour_available
      ]
    },
    request_slide: {
      steps: [
        {
          element: '.respond-to',
          intro: '<pre>' +
          'You can accept, decline or make an\n' +
          'an offer here.\n' +
          '</pre>',
          position: 'left'
        },
        {
          element: '.message-links',
          intro: '<pre>' +
          'You can correspond with the client and add\n' +
          'internal notes for your team members.\n' +
          '</pre>',
          position: 'left'
        },
        {
          element: '.followers',
          intro: '<pre>' +
          'Do you want anyone else to receive\n' +
          'notifications as you are communicating\n' +
          'with a client about a request? Add\n' +
          'followers here!\n' +
          '</pre>',
          position: 'left'
        }
      ]
    },
    clients: {
      steps: []
    }
  };

  // very dumb version control on the cache key.
  // We will keep track of having seen the intro by storing cache
  // entries in localStorage that look like
  // 'intro:v1:<intro_name>': true
  var version = 'v1';
  var name = 'intro'
  var cache_prefix = [name, version].join(':');

  /**
   * Inner class that exposes the interface for starting the introductions
   */
  var Intro = function () {
    var self = this;

    self.page = intro_page || null;

    self.getKey = function (val) {
      val = val || self.page;
      return [cache_prefix, val].join(':');
    };

    /**
     * Check localStorage for a cache key. If not found, then the intro will
     * launch when
     */
    self.shouldIntroduce = function () {
      if (self.page === null || self.page == undefined) {
        return false;
      }
      return (localStorage.getItem(self.getKey())==="true")? false:true;
    };

    /**
     * This does not belong here at all, but meh. This will clear anything
     * that looks like intro:v1:<something>. Also. It doesn't clobber
     * whatever else is in localstorage.
     */
    self.resetAllIntros = function () {
      var regex = /^intro:v1:(\w+)$/;
      for (var i in localStorage) {
        if (regex.test(i)) {
          localStorage.removeItem(i);
        }
      }
      return;
    };

    /**
     * Just a wrapper on localStorage's removal function. No need to use
     * this, but here for cohesion.
     */
    self.resetIntro = function (page) {
      page = page || self.page;
      return localStorage.removeItem(self.getKey(page));
    };

    /**
     * Only show an intro if not currently saved in localStorage
     */
    self.start = function() {
      if (self.shouldIntroduce() && self.page !== null) {
        return self.startIntro();
      }
    };

    /**
     * Force an intro start, regardless if we've seen it before or not
     */
    self.startIntro = function () {
      // really dumb check to see if we are on mobile
      if (/^m\..*$/.test(window.location.host) === false) {
        var intro = introJs();
        intro = intro.setOptions(pages[self.page]);
        intro.start();
        localStorage.setItem(self.getKey(), true);
      }
      return;
    };
  };
  return new Intro();
};

Pmp.Utils.attachDataTablesToSearch = function (table, q) {
  table.on('search', _.debounce(() => requestAnimationFrame(() => {
    const query = table.search()
    const params = new URLSearchParams(Pmp.Client.IsOnAjaxPage() ? $.address.queryString() : window.location.search)
    if (query) {
      params.set(q, query)
    } else {
      params.delete(q)
    }
    const serializedParams = `${params}`
    if (Pmp.Client.IsOnAjaxPage()) {
      var prevHistory = $.address.history()
      $.address.history(false).queryString(serializedParams).history(prevHistory)
    } else {
      window.history.replaceState({}, '', `${window.location.pathname}${serializedParams && '?'}${serializedParams}`)
    }
  }), 100))
  requestAnimationFrame(() => {
    const query = new URLSearchParams(Pmp.Client.IsOnAjaxPage() ? $.address.queryString() : window.location.search).get(q)
    if (query) {
      table.search(query).draw()
    }
  })
}

Pmp.Utils.copyToClipboardWithIndicator = async function(value, textElement, successText) {
  await navigator.clipboard.writeText(value).then(() => {
    if (textElement && successText) {
      textElement.text = successText
    }
  });

  return false
}
