
/* Array Functions */
Pmp.Utils.Array = Pmp.Utils.Array || {};

/**
 * Find insertion point for a value val, as specified by the comparator 
 * (a function)
 * @param sortedArr The sorted array
 * @param val The value for which to find an insertion point (index) in the array
 * @param comparator The comparator function to compare two values
 */
Pmp.Utils.Array.FindInsertionPoint = function(sortedArr, val, comparator) {   
   var low = 0, high = sortedArr.length;
   var mid = -1, c = 0;
   while(low < high)   {
      mid = parseInt((low + high)/2);
      c = comparator(sortedArr[mid], val);
      if(c < 0)   {
         low = mid + 1;
      }else if(c > 0) {
         high = mid;
      }else {
         return mid;
      }
   }
   return low;
};

/**
 * A simple built-in comparator
 */
Pmp.Utils.Array.DefaultComparator = function(val1, val2)  {
   if ('' + parseFloat(val1) === val1 && '' + parseFloat(val2) === val2) {
	   val1 = parseFloat(val1);
	   val2 = parseFloat(val2);
   }
   if(val1 > val2)   {
      return 1;
   } else if (val1 < val2)   {
      return -1;
   }
   return 0;
};

Pmp.Utils.Array.InsertSorted = function(element, array, comparator) {
	comparator = comparator || Pmp.Utils.Array.DefaultComparator;
	var idx = Pmp.Utils.Array.FindInsertionPoint(array, element, comparator);
	array.splice(idx, 0, element);
};
